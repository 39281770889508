import React from 'react'
import InvitedSpeakers from '../components/InvitedSpeakers/InvitedSpeakers'

const Invited = () => {
  return (
    <>
      <InvitedSpeakers />
    </>
  )
}

export default Invited
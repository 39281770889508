import konferenca1 from "../img/konferenca1.JPG";
import image1 from "../img/galery1.JPG"
import image2 from "../img/galery2.JPG"
import image3 from "../img/galery3.JPG"
import image4 from "../img/galery4.JPG"
import image5 from "../img/galery5.JPG"
import image6 from "../img/galery6.JPG"
import image7 from "../img/galery7.JPG"
import image8 from "../img/galery8.JPG"
// import image9 from "../img/conf1.jpg"
import image10 from "../img/conf4.jpg"
import image11 from "../img/conf3.jpg"
import image12 from "../img/conf4.jpg"
import image13 from "../img/conf5.jpg"
import image14 from "../img/conf6.jpg"
import image15 from "../img/conf7.jpg"
import image16 from "../img/conf8.jpg"
import image17 from "../img/conf9.jpg"
import image18 from "../img/conf10.jpg"
import image19 from "../img/conf11.jpg"
import image20 from "../img/conf12.jpg"
import image21 from "../img/conf13.jpg"
import image22 from "../img/conf14.jpg"
import image23 from "../img/conf15.jpg"
import image24 from "../img/conf16.jpg"
import image25 from "../img/conf17.jpg"
import image26 from "../img/conf18.jpg"
import image27 from "../img/conf19.jpg"
import image28 from "../img/conf20.jpg"
import image29 from "../img/conf21.jpg"


const conferences = [
 
      {
        id: 1,
        imgSrc: konferenca1,
        gallery: [image1, image2, image3, image4, image5, image6, image7, image8, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29],
        title: "1st Congress of KAO",
      }
    ,

  ];

  export { conferences }
  
import React from 'react'
import SectionBanner from '../components/Banner/SectionBanner'

const PresidentLetter = () => {
  return (
    <>
      <SectionBanner />
    </>
  )
}

export default PresidentLetter
import React from 'react';

const Programm = () => {
  return (
    <div className='bg-gray-50 px-4 lg:px-16  py-5'>
    <section className="bg-gray-50">
                <div className="mx-auto py-5">
                    <div className="">
                        <h2 className="mb-2 text-2xl font-semibold text-[#044f7c] font-custom uppercase">
                            Agenda of Programe
                        </h2>
                        <p className="text-sm font-medium">
                            <p className="text-sm 2xl:text-base font-medium lg:w-[68%] 2xl:w-[55%] font-custom">
                               
                            </p>
                        </p>
                    </div>
                </div>
            </section>
      
      <div className='overflow-x-auto'>
        <table className='min-w-full bg-white overflow-hidden'>
          <tbody className='text-[#044f7c] font-custom font-extrabold'>
            {/* Day 1 */}
            <tr>
              <td colSpan='3' className='px-6 py-4 bg-[#044f7c] text-white text-lg font-semibold border-b border-gray-200'>
                Day 1 - 25 October 2024
              </td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>08:00 - 18:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-extrabold'>Registration</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>All day</td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>09:00 - 10:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>YO Session</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>10:30 - 10:45</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Coffee Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>10:45 - 11:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Opening Ceremony</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Huban Atilla & Naser Salihu<br />Short artistic program</td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>11:30 - 12:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Innovations in Cornea and Ocular Surface</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>12:30 - 13:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Lunch Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>13:30 - 14:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Cataract and refractive Surgery – I</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>14:30 - 15:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Glaucoma – Recent advances in glaucoma diagnosis and treatment</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>15:30 - 16:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Coffee Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>16:00 - 17:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Retina - Imaging</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>17:00 - 18:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Vitreoretinal Surgery - 1</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            
            {/* Day 2 */}
            <tr>
              <td colSpan='3' className='px-6 py-4 bg-[#044f7c] text-white text-lg font-semibold border-t border-gray-200'>
                Day 2 - 26 October 2024
              </td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>08:00 - All Day</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Registration</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>09:00 - 10:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Uveitis</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>10:00 - 11:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Vitreoretinal Surgery-2</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>11:00 - 11:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Coffee Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>11:30 - 12:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>SATELLITE SYMPOSIUM</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>12:30 - 13:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Lunch Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>13:30 - 14:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Strabismus, Ambliyopia</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>14:30 - 15:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Refractive Surgery - II</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>15:30 - 16:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Coffee Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>16:00 - 17:00</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Oculoplastics</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>17:00 - 17:20</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Coffee Break</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>17:20 - 18:20</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Future Developments in Ophthalmology</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-slate-50'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'>18:20 - 18:30</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Closing Ceremony</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
            <tr className='bg-yellow-400 text-white'>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200 font-bold'></td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'>Gala Dinner</td>
              <td className='px-6 py-4 whitespace-nowrap border-b border-gray-200'></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Programm;
